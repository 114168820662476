/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { Provider } from 'react-redux';
import store from '../store/store';

// Component Imports
import StepTracker from './OnlineCreditApplication/StepTracker';

// Step Tracker Steps
// Business
import Verify from './OnlineCreditApplication/steps/Verify/Verify';
import CompanyInfo from './OnlineCreditApplication/steps/CompanyInfo/CompanyInfo';
import BankInfo from './OnlineCreditApplication/steps/BankInfo/BankInfo';
import PersonalInfo from './OnlineCreditApplication/steps/PersonalInfo/PersonalInfo';

const BusinessCreditApplication = ({
  postUrl,
  channelID,
  offerId,
  derivativeId,
  term,
  mileage,
  initialPayment,
  optionIds,
  maintenanceSelected,
  verifyStep,
  verifyPostUrl,
}) => {
  const heading = 'Online Credit Application';

  const steps =
    verifyStep === false
      ? [
          { name: 'Company info', heading, component: <CompanyInfo /> },
          { name: 'Bank info', heading, component: <BankInfo /> },
          { name: 'Personal info', heading, component: <PersonalInfo finalStep showOptOut /> },
        ]
      : [
          { name: 'Verify', heading, component: <Verify /> },
          { name: 'Company info', heading, component: <CompanyInfo /> },
          { name: 'Bank info', heading, component: <BankInfo /> },
          { name: 'Personal info', heading, component: <PersonalInfo finalStep /> },
        ];

  return (
    <Provider store={store}>
      <StepTracker
        steps={steps}
        channelID={channelID}
        postURL={postUrl}
        offerId={offerId}
        derivativeId={derivativeId}
        term={term}
        mileage={mileage}
        initialPayment={initialPayment}
        optionIds={optionIds}
        maintenanceSelected={maintenanceSelected}
        verifyStep={verifyStep}
        verifyPostUrl={verifyPostUrl}
      />
    </Provider>
  );
};

BusinessCreditApplication.propTypes = {
  postUrl: PropTypes.string,
  channelID: PropTypes.string.isRequired,
  offerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  derivativeId: PropTypes.number,
  term: PropTypes.number,
  mileage: PropTypes.number,
  initialPayment: PropTypes.string,
  optionIds: PropTypes.arrayOf(PropTypes.string),
  maintenanceSelected: PropTypes.bool,
  verifyStep: PropTypes.bool,
  verifyPostUrl: PropTypes.string,
};

BusinessCreditApplication.defaultProps = {
  verifyStep: true,
};

export default BusinessCreditApplication;
