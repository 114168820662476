import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    exteriorImages: Array,
    interiorImages: Array,
  };

  initialize() {
    this.handleCustomEvent = this.handleCustomEvent.bind(this);
    document.addEventListener('alt-gallery:open', this.handleCustomEvent);
  }

  disconnect() {
    document.removeEventListener('alt-gallery:open', this.handleCustomEvent);
  }

  openLightbox(event) {
    event.preventDefault();
    const { url, type } = event.target.dataset;

    this.showLightbox(url, type);
  }

  handleCustomEvent(event) {
    const { imageUrl, type } = event.detail;
    this.showLightbox(imageUrl, type);
  }

  showLightbox(imageUrl, type = 'all') {
    const lightboxElement = document.getElementById('alt-gallery-lightbox');
    const mainElement = document.getElementsByTagName('main');
    if (lightboxElement && mainElement.length > 0) {
      document.body.style.overflowY = 'hidden';
      mainElement[0].style.zIndex = '3';
      lightboxElement.style.display = 'flex';

      // Dispatch event for React component
      const lightboxEvent = new CustomEvent('lightbox:open', {
        detail: {
          imageUrl,
          type,
        },
      });
      window.dispatchEvent(lightboxEvent);
    }
  }

  closeLightbox(event) {
    const mainElement = document.getElementsByTagName('main');
    // Allow closing when clicking on the background (drv-lightbox-modal) or the close button
    if (!event.target.matches('.drv-lightbox-modal, .drv-lightbox-modal__close')) {
      return;
    }

    document.body.style.overflowY = 'auto';
    mainElement[0].style.zIndex = '1';
    this.element.style.display = 'none';
  }
}
