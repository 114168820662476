import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';

import GalleryTabs from './GalleryTabs';
import GallerySlide from './GallerySlide';
import GalleryNavigation from './GalleryNavigation';

const ContentIntroGallery = ({ exteriorImages, interiorImages }) => {
  const [selectedTab, setSelectedTab] = useState('exterior');
  const [loadedImages, setLoadedImages] = useState(new Set());
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'center',
    skipSnaps: false,
    dragFree: false,
    breakpoints: {
      '(min-width: 768px)': { align: 'start' },
    },
  });

  const alternateImages = (extImages, intImages) => {
    if (!intImages || intImages.length === 0) {
      return extImages;
    }

    const result = [];
    const maxLength = Math.max(extImages.length, intImages.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < extImages.length) {
        result.push(extImages[i]);
      }
      if (i < intImages.length) {
        result.push(intImages[i]);
      }
    }

    return result;
  };

  useEffect(() => {
    if (interiorImages?.length) {
      setSelectedTab('all');
    }
  }, [interiorImages]);

  const allImages = [...alternateImages(exteriorImages, interiorImages || [])];

  // Preload all images on mount
  useEffect(() => {
    const allImgs = [...alternateImages(exteriorImages, interiorImages || [])];
    allImgs.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setLoadedImages((prev) => new Set([...prev, url]));
      };
    });
  }, [exteriorImages, interiorImages]);

  // Listen for gallery param changes
  useEffect(() => {
    const handleGalleryChange = (event) => {
      const { gallery } = event.detail;
      if (gallery === 'interior' && interiorImages?.length >= 2) {
        setSelectedTab('interior');
        emblaApi?.scrollTo(0);
      }
    };

    window.addEventListener('galleryParamChanged', handleGalleryChange);
    return () => window.removeEventListener('galleryParamChanged', handleGalleryChange);
  }, [emblaApi, interiorImages]);

  const filteredImages = () => {
    if (!interiorImages?.length) {
      return exteriorImages;
    }
    switch (selectedTab) {
      case 'interior':
        return interiorImages;
      case 'exterior':
        return exteriorImages;
      default:
        return allImages;
    }
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    emblaApi?.scrollTo(0);
  };

  const handleImageClick = (image) => {
    const event = new CustomEvent('alt-gallery:open', {
      detail: {
        imageUrl: image,
        type: selectedTab,
      },
    });
    document.dispatchEvent(event);
  };

  return (
    <div className="drv-deal-content__gallery">
      {interiorImages?.length >= 2 && (
        <GalleryTabs selectedTab={selectedTab} onTabChange={handleTabChange} interiorImages={interiorImages} />
      )}

      <div className="drv-deal-content__carousel" ref={emblaRef}>
        <div className="drv-deal-content__carousel-container">
          {filteredImages().map((image, index) => (
            <GallerySlide
              key={image}
              image={image}
              index={index}
              onClick={() => handleImageClick(image)}
              style={{ visibility: loadedImages.has(image) ? 'visible' : 'hidden' }}
            />
          ))}
        </div>
      </div>

      {filteredImages().length > 1 && <GalleryNavigation emblaApi={emblaApi} />}
    </div>
  );
};

ContentIntroGallery.propTypes = {
  exteriorImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  interiorImages: PropTypes.arrayOf(PropTypes.string),
};

ContentIntroGallery.defaultProps = {
  interiorImages: [],
};

export default ContentIntroGallery;
