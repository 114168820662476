import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const GalleryTabs = ({ selectedTab, onTabChange }) => {
  const tabs = [
    { id: 'all', label: 'All' },
    { id: 'interior', label: 'Interior' },
    { id: 'exterior', label: 'Exterior' },
  ];

  return (
    <div className="drv-deal-content__gallery-tabs">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={classNames('drv-deal-content__gallery-tab', {
            'drv-deal-content__gallery-tab--active': selectedTab === tab.id,
          })}
          onClick={() => onTabChange(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

GalleryTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default GalleryTabs;
