import React from 'react';
import PropTypes from 'prop-types';

const GalleryNavigation = ({ emblaApi }) => {
  const scrollPrev = () => emblaApi?.scrollPrev();
  const scrollNext = () => emblaApi?.scrollNext();

  return (
    <>
      <button
        className="drv-deal-content__carousel-button drv-deal-content__carousel-button--prev"
        onClick={scrollPrev}
        aria-label="Previous slide"
      />
      <button
        className="drv-deal-content__carousel-button drv-deal-content__carousel-button--next"
        onClick={scrollNext}
        aria-label="Next slide"
      />
    </>
  );
};

GalleryNavigation.propTypes = {
  emblaApi: PropTypes.shape({
    scrollPrev: PropTypes.func,
    scrollNext: PropTypes.func,
  }),
};

GalleryNavigation.defaultProps = {
  emblaApi: null,
};

export default GalleryNavigation;
