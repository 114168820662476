import React from 'react';
import PropTypes from 'prop-types';

const GallerySlide = ({ image, index, onClick, style }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };

  return (
    <div
      className="drv-deal-content__carousel-slide"
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      style={style}
    >
      <img src={image} alt="" className="drv-deal-content__carousel-image" data-index={index} style={style} />
    </div>
  );
};

GallerySlide.propTypes = {
  image: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.shape({
    visibility: PropTypes.string,
  }),
};

GallerySlide.defaultProps = {
  style: null,
};

export default GallerySlide;
